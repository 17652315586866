const routes = {
  createUser: '/auth/add',
  getUsers: '/users',
  updateUser: 'users/update',
  getProfile:'users/profile',
  updateUserStatus: 'users/disable',
  getUserPermissions: 'users/permissions',
  UpdatePassword:'/auth/changePassword'
  
};

export default routes