import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Container, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import RegisterContainer from 'container/Register'
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { PrimaryButton } from 'components/Buttons';
import Colors from 'assets/Style/Colors';
import DeleteIcon from '@mui/icons-material/Delete';
import { Controller, useForm } from 'react-hook-form';
import UploadFile from 'components/UploadFile';
import InputField from 'components/Input';
import DatePicker from 'components/DatePicker';
import { ErrorToaster } from 'components/Toaster';
import { FormControl } from '@mui/base';
import LabelCustomInput from 'components/Input/LabelCustomInput';
import SelectField from 'components/Select';
import { CleanTypes, Debounce2, getFileSize, handleDownload } from 'utils';
import instance from 'config/axios';
import routes from 'services/System/routes';
import CustomerServices from 'services/Customer';
import CustomerService from '../DashboardPages/CustomerService';
import { showErrorToast, showPromiseToast } from 'components/NewToaster';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addMonths, set } from 'date-fns';
import UploadFileSingle from 'components/UploadFileSingle';
import { Images } from 'assets';
import { useAuth } from 'context/UseContext';
import { useCallbackPrompt } from 'hooks/useCallBackPrompt';
import { useJsApiLoader } from '@react-google-maps/api';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

function UpdateCustomer() {
  const navigate = useNavigate()
  const { user } = useAuth()
  const theme = useTheme();
  const { id } = useParams()
  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    setValue: setValue1,
    getValues: getValues1,
    formState: { errors: errors1 },

  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },

  } = useForm();

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
    getValues: getValues3,
    setValue: setValue3,

  } = useForm();
  const {
    register: register4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4 },
    getValues: getValues4,
    setValue: setValue4,

  } = useForm();
  const {
    register: register5,
    handleSubmit: handleSubmit5,
    formState: { errors: errors5 },
    getValues: getValues5,
    setValue: setValue5,

  } = useForm();
  const {
    register: register6,
    handleSubmit: handleSubmit6,
    formState: { errors: errors6 },
    control: control6,
    getValues: getValues6,
    setValue: setValue6,

  } = useForm();

  const allowFilesType = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  const allowFilesType2 = [
    'image/png',
    'image/jpg',
    'image/jpeg',

  ];
  const [guarantors, setGuarantors] = useState([])
  const [activeStep, setActiveStep] = React.useState(1);
  const [radiovalue, setradiovalue] = useState('payroll')
  const [date, setDate] = useState(null)
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [agents, setAgents] = useState([])
  const [selectedDesposit, setSelectedDesposit] = useState(null)
  const [depositList, setDepositList] = useState([])
  const [depositChequesList, setDepositChequesList] = useState([])
  const [monthlyVisaFee, setMonthlyVisaFee] = useState(true)
  const [chequeImage, setChequeImage] = useState()
  // *For Deposit Slip
  const [progress, setProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [slipDetail, setSlipDetail] = useState([]);
  const [slipLink, setSlipLink] = useState("");
  const [tradeLiscense, setTradeLiscense] = useState()
  const [attorney, setAttorney] = useState()
  const [ventureAgreement, setVentureAgreement] = useState()
  const [MoA, setMoA] = useState()
  const [vat, setVat] = useState()
  const [emirateIds, setEmirateIds] = useState()
  const [guaranatorEmirateId, setGuaranatorEmirateId] = useState()
  const [agreement, setAgreement] = useState()
  const [otherDoc, setOtherDoc] = useState()
  const [tradeLicenseWithExpiry, setTradeLicenseWithExpiry] = useState('');
  const [attorneyWithExpiry, setAttorneyWithExpiry] = useState('');
  const [ventureAgreementWithExpiry, setVentureAgreementWithExpiry] = useState('');
  const [MoAWithExpiry, setMoAWithExpiry] = useState('');
  const [vatWithExpiry, setVatWithExpiry] = useState('');
  const [emirateIdsWithExpiry, setEmirateIdsWithExpiry] = useState('');
  const [guarantorEmirateIdWithExpiry, setGuarantorEmirateIdWithExpiry] = useState('');
  const [agreementWithExpiry, setAgreementWithExpiry] = useState('');
  const [otherDocWithExpiry, setOtherDocWithExpiry] = useState('');
  const [passportWithExpiry, setPassportWithExpiry] = useState(``)
  const [passport, setPassport] = useState()
  const [allocation, setAllocation] = useState(false)
  const [selectedDue, setSelectedDue] = useState(null)
  const [depositError, setDepositError] = useState(false)
  const [depositTotal, setDepositTotal] = useState()
  const [isUploading, setIsUploading] = useState(false);
  const [loader, setLoader] = useState(false)
  const [depositBalance, setDepositBalance] = useState(0)
  const [eligibilityBalance, setEligibilityBalance] = useState(0)
  const [orgEligibility, setOrgEligibility] = useState(0)
  const [addbutton, setAddbutton] = useState(false)

  const [fieldsDisabled, setFieldsDisabled] = useState({
    monthlyVisaServiceCharges: false,
    vipMedical: false,
    extraTyping: true,
  });

  const [vipMedicalCheck, setVipMedicalCheck] = useState(false)
  
  const [monthlyChargesCheck, setMonthlyChargesCheck] = useState(false)
  const [customerDetails, setCustomerDetails] = useState(null)
  const [loading, setLoading] = useState(false)
  const [emailVerify, setEmailVerify] = useState(true)

  // *For Stepper Forms Data
  const [stepFormData, setStepFormData] = useState()
  const [step1FormData, setStep1FormData] = useState();
  const [step2FormData, setStep2FormData] = useState();
  const [step3FormData, setStep3FormData] = useState();
  const [step4FormData, setStep4FormData] = useState();
  const [step5FormData, setStep5FormData] = useState()
  const [submit, setSubmit] = useState(true)
  const [handleBlockedNavigation] =
    useCallbackPrompt(submit)
  let url = localStorage.getItem('currentRoute')

  //documents array
  const [documents, setDocuments] = useState(
    [
      {
        name: "Guarantor Passport",
        key: "guaranatorPassport",
        path: "",
        expiry_date: null
      },


      {
        name: "Venture Agreement",
        key: "ventureagreement",
        path: "",
        expiry_date: null
      },
      {
        name: "Attorney",
        key: "attorney",
        path: "",
        expiry_date: null
      },
      {
        name: "MoA",
        key: "MoA",
        path: "",
        expiry_date: null
      },
      {
        name: "VAT",
        key: "VAT",
        path: "",
        expiry_date: null
      },
      {
        name: "Other Doc",
        key: "otherdoc",
        path: "",
        expiry_date: null
      },

      {
        name: "Trade License",
        key: "tradeLicense",
        path: "",
        expiry_date: null
      },
      {
        name: "Passport",
        key: "passport",
        path: "",
        expiry_date: null
      },
      {
        name: "Emirate Ids",
        key: "emirateIds",
        path: "",
        expiry_date: null
      },
      {
        name: "Guaranator Emirate Id",
        key: "guaranatorEmirateId",
        path: "",
        expiry_date: null
      },
      {
        name: "Agreement",
        key: "Agreement",
        path: "",
        expiry_date: null
      }
    ]
  )
  // document states
  const [companyLogo, setCompanyLogo] = useState(null)
  const [guaranatorPassport, setGuaranatorPassport] = useState(null)
  const [guranaratorPassportExpiryDate, setGuranaratorPassportExpiryDate] = useState(null)
  const [emirateId, setEmirateId] = useState(null)
  const [emirateIdExpiryDate, setEmirateIdExpiryDate] = useState(null)


  const getAgents = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 50,
        search: search,
      };
      const { data } = await CustomerServices.getAgents(params);
      setAgents(data?.rows)
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleNext = () => {
    console.log(activeStep)
    if (activeStep === 4 && user?.user_type == "C") {
      setActiveStep(6)

    }
    else if (activeStep === 2 && user?.user_type == "C") {
      setActiveStep(4)
    }

    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

    }
  };

  const handleBack = () => {
    if (activeStep === 6 && user?.user_type == "C") {
      setActiveStep(4)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleDelete = async (orderBy) => {
    console.log(orderBy, 'idsss');
    console.log(guarantors);

    const updatedGuarantors = guarantors.filter(guarantor => guarantor.order_by !== orderBy);
    console.log(updatedGuarantors);
    setGuarantors(updatedGuarantors)

  }
  const AddDeposits = (value, type) => {

    if (value > 0 && type) {

      console.log(getValues3('depositperVisa'));
      console.log(getValues3('depositAmount'));
      if (value <= 0) {
        showErrorToast("Value must be greater than 0")
      }
  
      else {

        const updatedDepositList = [...depositList, { amount: value, payment_type: type, consumed: 0 }];

        setDepositList(updatedDepositList);
        setValue('cash', '')
      }

    }
  
  }
  const AddDepositCheques = (value, type) => {
    console.log(getValues('chequeAmount'), chequeImage, 'asdasdas');

    if (value == '' || !chequeImage) {

      showErrorToast('Please complete required fields')

    }
    else if (value <= 0) {
      showErrorToast("Value must be greater than 0")
    }
    else {
      console.log(value && type && chequeImage);
     
        

        setValue('chequeAmount', '')

        const updatedDepositList = [...depositChequesList, { amount: value, payment_type: type, cheque_document: chequeImage, consumed: 0 }];
        console.log(updatedDepositList);
        setChequeImage('')
        setValue('chequeImage', '')
        setDepositChequesList(updatedDepositList);
      

    }


  }
  const deleteDepositCheque = (index) => {

    const updatedDepositList = depositChequesList.filter((_, i) => i !== index);

    setDepositChequesList(updatedDepositList);
  };
  const deleteDeposit = (index) => {

    const updatedDepositList = depositList.filter((_, i) => i !== index);

    setDepositList(updatedDepositList);
  };
  const handleDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setGuranaratorPassportExpiryDate("invalid");
        return;
      }
      setGuranaratorPassportExpiryDate(new Date(newDate));
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handlepassportDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setGuranaratorPassportExpiryDate("invalid");
        return;
      }
      setGuranaratorPassportExpiryDate(new Date(newDate));
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleEmirateDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {
        setEmirateIdExpiryDate("invalid");
        return;
      }
      setEmirateIdExpiryDate(new Date(newDate));
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const updateResult = (key, newResult) => {

    console.log(newResult)
    const updatedDocuments = documents.map(doc => {
      if (doc.key === key) {
        return { ...doc, path: newResult }; // Update the path
      }
      return doc; // Return the document as is if the key doesn't match
    });
    console.log(updatedDocuments, 'updatedDocuments');
    setDocuments(updatedDocuments)
  };

  // *For Upload Document
  const handleUploadDocument = async (e, key) => {
    setLoader(key)
    try {
      e.preventDefault();
      let path = "";
      console.log(e.target.files, "length");

      const inputElement = e.target; // Store a reference to the file input element

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        let arr = [
          {
            name: file?.name,
            file: "",
            type: file?.type.split("/")[1],
            size: getFileSize(file.size),
            isUpload: false,
          },
        ];

        let maxSize = 10 * 1024 * 1024;
        if (file.size > maxSize) {
          showErrorToast('File Size Must Be Less than 10 MB');
        } else {
          if (allowFilesType.includes(file.type)) {
            // Add the current date before the file name
            const currentDate = new Date().toISOString().split('T')[0]; // e.g., "2024-08-23"
            const uniqueFileName = `${currentDate}_${file.name}`;

            // Create a new file with the date-prefixed name
            const newFile = new File([file], uniqueFileName, { type: file.type });

            // Upload the file with the new name
            const uploadedPath = await handleUpload(newFile, arr);

            if (path) {
              path += "," + uploadedPath;
            } else {
              path = uploadedPath;
            }
            setLoader('')
          } else {
            showErrorToast(`File type ${file.type} is not allowed.`);
          }
        }
      }

      console.log(path, "path");

      // Clear the file input after processing
      inputElement.value = "";

      return path;
    } catch (error) {
      ErrorToaster(error);
    }
  };


  // *For Upload Document
  const handleUploadDocument2 = async (e, key) => {
    setLoader(key)
    try {
      e.preventDefault();
      let path = "";
      console.log(e.target.files, "length");

      const inputElement = e.target; // Store a reference to the file input element

      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        let arr = [
          {
            name: file?.name,
            file: "",
            type: file?.type.split("/")[1],
            size: getFileSize(file.size),
            isUpload: false,
          },
        ];

        let maxSize = 10 * 1024 * 1024;
        if (file.size > maxSize) {
          showErrorToast('File Size Must Be Less than 10 MB');
        } else {
          if (allowFilesType.includes(file.type)) {
            // Add the current date before the file name
            const currentDate = new Date().toISOString().split('T')[0]; // e.g., "2024-08-23"
            const uniqueFileName = `${currentDate}_${file.name}`;

            // Create a new file with the date-prefixed name
            const newFile = new File([file], uniqueFileName, { type: file.type });

            // Upload the file with the new name
            const uploadedPath = await handleUpload(newFile, arr);

            if (path) {
              path += "," + uploadedPath;
            } else {
              path = uploadedPath;
            }
            setLoader('')
          } else {
            showErrorToast(`File type ${file.type} is not allowed.`);
          }
        }
      }

      console.log(path, "path");

      // Clear the file input after processing
      inputElement.value = "";

      return path;
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleUpload = async (file, docs) => {
    setProgress(0);
    try {
      const formData = new FormData();
      formData.append("document", file);
      console.log(file);
      const { data } = await instance.post(routes.uploadDocuments, formData, {
        onUploadProgress: (progressEvent) => {
          const uploadedBytes = progressEvent.loaded;
          const percentCompleted = Math.round(
            (uploadedBytes * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
          console.log(getFileSize(uploadedBytes));
          setUploadedSize(getFileSize(uploadedBytes));
        },
      });
      if (data) {
        docs[0].isUpload = true;
        docs[0].file = data?.data?.nations;
        setSlipDetail(docs);
        console.log(data, 'asddasasd');
        return data?.data?.path

      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const submitForm = async (formData) => {
    console.log(formData);
    try {
      let obj = {
        name: formData?.companyName,
        logo: companyLogo,
        phone: formData?.mobile,
        email: formData?.email,
        address: formData?.businessAddress,
        website: formData?.businessWebsite,
        cp_name: formData?.personName,
        cp_mobile: formData?.phone,

      };

      setStepFormData(obj);
      handleNext()
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleDocArrayUpdate = async (field, value, key) => {
    console.log(documents);

    if (field === 'path') {
      const updatedDocuments = documents.map(doc => {
        if (doc.key === key) {
          return { ...doc, path: value }; // Update the path
        }
        return doc; // Return the document as is if the key doesn't match
      });
      console.log(updatedDocuments);
      // Assuming you want to update the documents array
      // You can replace the following line with your state updating logic
      setDocuments(updatedDocuments)
    } else {
      const updatedDocuments = documents.map(doc => {
        if (doc.key === key) {
          return { ...doc, expiry_date: value === null ? null : moment(value).format('MM-DD-YYYY') }; // Update the path
        }
        return doc; // Return the document as is if the key doesn't match
      });
      console.log(updatedDocuments);
      setDocuments(updatedDocuments)
      // Handle other fields if needed
    }
  }

  const verifyEmail = async (value) => {
    let email = getValues1('email')
    if (email) {

      try {
        let obj = {
          email: email.toLowerCase(),
          validate: true,
          user_id: customerDetails?.user_id


        };

        console.log(obj);

        const { status } = await CustomerServices.addCustomer(obj);

        console.log(status);
        if (status) {
          setEmailVerify(true)
        }


      } catch (error) {
        console.log(error);
        setEmailVerify(false)
        showErrorToast(error)
      }
    }
  };

  const handleCheckboxChange = (name) => (event) => {
    setFieldsDisabled({
      ...fieldsDisabled,
      [name]: !event.target.checked,
    });
    console.log({
      ...fieldsDisabled,
      [name]: !event.target.checked,
    });
  };

  function validateDocuments(data) {
    let result = false; // This can be used for further validation handling

    // Passport expiry should be at least 6 months from today
    const passportExpiry = data?.passportExp ? moment(data.passportExp).startOf('day') : null;
    if (passportExpiry && passportExpiry < moment().add(6, 'months').startOf('day')) {
      showErrorToast('Passport Expiry Date is not valid. It must be at least 6 months later than ' + moment().format('MM-DD-YYYY'));
      result = true;
    }

    // License expiry should not be earlier than today
    const licenseExpiry = data?.LiscenseExp ? moment(data.LiscenseExp).startOf('day') : null;
    if (licenseExpiry && licenseExpiry < moment().startOf('day')) {
      showErrorToast('License Expiry Date is not valid. It cannot be earlier than today (' + moment().format('MM-DD-YYYY') + ')');
      result = true;
    }

    // Emirates ID expiry should not be earlier than today
    const emirateIdsExpiry = data?.emirateIDsExpiry ? moment(data.emirateIDsExpiry).startOf('day') : null;
    if (emirateIdsExpiry && emirateIdsExpiry < moment().startOf('day')) {
      showErrorToast('Emirates ID Expiry Date is not valid. It cannot be earlier than today (' + moment().format('MM-DD-YYYY') + ')');
      result = true;
    }

    // Agreement expiry should not be earlier than today
    const agreementExpiry = data?.agreementExpiry ? moment(data.agreementExpiry).startOf('day') : null;
    if (agreementExpiry && agreementExpiry < moment().startOf('day')) {
      showErrorToast('Agreement Expiry Date is not valid. It cannot be earlier than today (' + moment().format('MM-DD-YYYY') + ')');
      result = true;
    }

    // Venture Agreement expiry can be checked similarly if required
    const ventureAgreementExpiry = data?.VentureagreementExpiry ? moment(data.VentureagreementExpiry).startOf('day') : null;
    if (ventureAgreementExpiry && ventureAgreementExpiry < moment().startOf('day')) {
      showErrorToast('Venture Agreement Expiry Date is not valid. It cannot be earlier than today (' + moment().format('MM-DD-YYYY') + ')');
      result = true;
    }

    return result;
  }


  const handelDate = (newDate) => {
    try {
      // eslint-disable-next-line eqeqeq
      if (newDate == "Invalid Date") {

        return;
      }
      return newDate ? new Date(newDate) : newDate

    } catch (error) {
      ErrorToaster(error);
    }
  };

  const submitForm1 = async (formData) => {
    console.log(formData);
    try {
      let obj = {
        name: formData?.companyName,
        logo: companyLogo,
        phone: formData?.mobile,
        email: formData?.email,
        address: formData?.businessAddress,
        website: formData?.businessWebsite,
        cp_name: formData?.personName,
        cp_mobile: formData?.phone,
        vat_no: formData?.vat

      };

      setStep1FormData(obj);
      handleNext()
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const submitForm2 = async (formData) => {
    console.log(formData);
    if (!formData?.passportExp || !formData?.emirateExp) {
      showErrorToast('Expiry Date is Required')
    }
    else {

      let expDate = moment(formData?.passportExp).startOf('day'); // Passport expiry date
      let expDateEmirate = moment(formData?.emirateExp).startOf('day'); // Emirate ID expiry date
      let currentDate = moment().startOf('day'); // Current date without time
      let sixMonthsFromNow = moment().add(6, 'months').startOf('day'); // 6 months from now

      console.log(expDate.format('YYYY-MM-DD'));
      console.log(sixMonthsFromNow.format('YYYY-MM-DD'));
      console.log(expDate.isBefore(sixMonthsFromNow)); // Proper way to compare dates

      // Check if the passport expiry date is less than 6 months from now
      if (expDate.isBefore(sixMonthsFromNow)) {
        showErrorToast('Passport Expiry Date is not Valid');
      }
      // Check if the Emirates ID expiry date is already passed
      else if (expDateEmirate.isBefore(currentDate)) {
        showErrorToast('Emirate ID Expiry Date is not Valid');
      }
      else {
        console.log(moment(formData?.passportExp).format('YYYY-MM-DD'));
        console.log(guaranatorPassport);

        try {
          // Get current length of the guarantors array to use as an index for order_by
          const orderIndex = guarantors.length + 1;

          let obj = {
            customer_id: id,
            name: formData?.guaranator,
            number: formData?.guaranatorNum,
            passport_documents: documents.find((item => item?.key == 'guaranatorPassport'))?.path,
            emirate_documents: documents.find((item => item?.key == 'guaranatorEmirateId'))?.path,
            passport_expiry: moment(formData?.passportExp).format('YYYY-MM-DD'),
            emirate_expiry: moment(formData?.emirateExp).format('YYYY-MM-DD'),
            order_by: orderIndex, // Add order_by key with the calculated index
          };

          console.log(obj);

          // Update guarantors with the new object
          setGuarantors((prevGuarantors) => [...prevGuarantors, obj]);

          // Clear form inputs
          setValue2('guaranator', '');
          setValue2('guaranatorNum', '');
          setValue2('emirateExp', '');
          setGuranaratorPassportExpiryDate(null);
          setEmirateIdExpiryDate(null);
          setValue2('passportExp', '');

          const updatedDocuments = documents.map(doc => {
            if (doc.key === 'guaranatorPassport' || doc.key === 'guaranatorEmirateId') {
              return { ...doc, path: "", expiry_date: null };
            }
            return doc;
          });
          setDocuments(updatedDocuments)

          // Set form data for the next step
          setStep2FormData(obj);
          // handleNext(); // Uncomment if needed
        } catch (error) {
          ErrorToaster(error);
        }
      }
    }
  };


  const submitForm3 = async (formData) => {
    console.log(formData);
    console.log([...depositList, ...depositChequesList]);
    const totalAmount = [...depositList, ...depositChequesList]?.reduce((total, payment) => total + parseFloat(payment.amount), 0);
    console.log(totalAmount);

    if (radiovalue == 'visa') {
      if (formData?.depositAmount != depositTotal) {
        showErrorToast('Desposits Must Be Equal')
      }
      else if ((parseFloat(getValues3('depositperVisa')) > parseFloat(getValues3('depositAmount'))) && radiovalue == 'visa') {
        showErrorToast("Deposit amount must be greater than deposit per visa")
      }

      else {

        if (totalAmount < depositBalance) {
          showErrorToast('Total Security Deposit can not be lower than consumed amount')
        }
        else {
          console.log(formData?.Payrollforvisa, 'testt');
          console.log(eligibilityBalance, 'testt');
          console.log(formData?.Payrollforvisa - eligibilityBalance, 'testt');

          try {
            let obj = {

              security_deposit_scenario: radiovalue, //per visa

              payroll_percentage: radiovalue == 'payroll' ? formData?.payroll : null,
              deposit_per_visa: radiovalue == 'payroll' ? null : formData?.depositperVisa,
              deposit_total: radiovalue == 'payroll' ? formData?.depositAmountpayroll : formData?.depositAmount,
              visa_eligibility: radiovalue == 'payroll' ? null : formData?.Payrollforvisa,
              payroll_eligibility: radiovalue == 'payroll' ? formData?.Payrollforvisa : null,
              deposit_balance: parseFloat(totalAmount) - parseFloat(depositBalance),
              visa_eligibility_remaining: formData?.Payrollforvisa - eligibilityBalance > 0 ? formData?.Payrollforvisa - eligibilityBalance : 0,
              deposits: [...depositList, ...depositChequesList]

            };
            console.log(obj);

            setStep3FormData(obj);
            handleNext()
          } catch (error) {
            ErrorToaster(error);
          }
        }
      }
    }
    else {
      if (formData?.depositAmountpayroll != depositTotal) {
        showErrorToast('Desposits Must Be Equal')
      }

      else if (totalAmount < depositBalance) {
        showErrorToast('Total Security Deposit can not be lower than consumed amount')
      }
      else {
        try {
          console.log(formData?.Payrollforvisa, 'testt');
          console.log(eligibilityBalance, 'testt');
          console.log(formData?.Payrollforvisa - eligibilityBalance, 'testt');

          let obj = {

            security_deposit_scenario: radiovalue, //per visa

            payroll_percentage: radiovalue == 'payroll' ? formData?.payroll : null,
            deposit_per_visa: radiovalue == 'payroll' ? null : formData?.depositperVisa,
            deposit_total: radiovalue == 'payroll' ? formData?.depositAmountpayroll : formData?.depositAmount,
            visa_eligibility: radiovalue == 'payroll' ? null : formData?.Payrollforvisa,
            payroll_eligibility: radiovalue == 'payroll' ? formData?.Payrollforvisa : null,
            deposit_balance: parseFloat(totalAmount) - parseFloat(depositBalance),
            visa_eligibility_remaining: formData?.Payrollforvisa - eligibilityBalance > 0 ? formData?.Payrollforvisa - eligibilityBalance : 0,
            deposits: [...depositList, ...depositChequesList]

          };

          setStep3FormData(obj);
          handleNext()
        } catch (error) {
          ErrorToaster(error);
        }
      }
    }

  };
  const submitForm4 = async (formData) => {
    console.log(formData, 'formData');
    let Verification = validateDocuments(formData)
    console.log(Verification);

    try {
      let obj = {


      };

      setStep4FormData(obj);
      if (!Verification) {

        handleNext()
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const submitForm5 = async (formData) => {
    console.log(formData);

    try {
      let obj = {
        open_allocation: allocation ? true : false,
        agent_id: allocation ? selectedAgent?.id : null,
        agent_user_id: allocation == 'true' ? selectedAgent?.user_id : null,
        agent_commission_visa: allocation ? Number(formData?.commission) : null,
        agent_commission_monthly: allocation ? Number(formData?.commissionMonthly) : null

      };
      console.log(obj);

      setStep5FormData(obj);
      handleNext()
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const submitForm6 = async (formData) => {
    console.log(formData);
    setSubmit(false)
    console.log(formData?.['1yearInsideRate']);
    const updatedDocuments = documents.map(doc => {
      if (doc.key === 'guaranatorPassport' || doc.key === 'guaranatorEmirateId') {
        return { ...doc, path: "", expiry_date: null };
      }
      return doc;
    });
    setDocuments(updatedDocuments)

    try {
      let obj = {
        id: id,
        user_id: customerDetails?.user_id,
        ...step1FormData,

        ...step3FormData,
        ...step4FormData,
        ...step5FormData,
        guarantors: guarantors,
        documents: updatedDocuments,
        one_year_inside: Number(formData?.['1yearInsideRate']),
        one_year_outside: Number(formData?.['1yearOutsideRate']),
        one_year_renewal: Number(formData?.['1yearRenewalRates']),
        two_year_inside: Number(formData?.['2yearInsideRate']),
        two_year_outside: Number(formData?.['2yearOutsideRate']),
        two_year_renewal: Number(formData?.['2yearRenewalRates']),
        absconder_fee: Number(formData?.['absconderFee']),
        overstay: Number(formData?.['overstayCost']),
        monthly_visa_service_below: monthlyChargesCheck ? Number(formData?.['monthlyVisaServiceCharges']) : null,
        monthly_visa_service_above: monthlyChargesCheck ? Number(formData?.['monthlyVisaServiceChargesabove']) : null,
        medical_extra: Number(formData?.['medicalCharges']),
        cancellation: Number(formData?.['cancellationRates']),
        rejection: Number(formData?.['rejectionCost']),
        due_date_policy: selectedDue?.id,




      };

      console.log(obj);

      const promise = CustomerServices.UpdateCustomer(obj);

      showPromiseToast(
        promise,
        'Saving...',
        'Added Successfully',
        'Something Went Wrong'
      );

      const result = await promise

      if (result.responseCode == 200 && user?.user_type == "C") {
        navigate(`/customer-detail/${id}`)

      } else if (result.responseCode == 200) {
        navigate('/customer-list')
      }

    } catch (error) {
      console.log(error);
    }
  };
  const eglibilityCalc = async (calctype) => {
    if (getValues3('depositAmount')?.includes('.')) {
      showErrorToast("Deppsit amount can not be in decimal")
      setAddbutton(true)
    }
    else {
      setAddbutton(false)
      if (calctype == 'payroll') {

        let multiplier = 100 / Number(getValues3('payroll'))
        console.log(isFinite(multiplier), 'multipliermultiplier');
        let amount = getValues3('depositAmountpayroll')
        if (isFinite(multiplier) && !isNaN(multiplier)) {

          setValue3('Payrollforvisa', parseFloat(parseFloat(parseFloat(amount || 0) * parseFloat(multiplier))).toFixed(2))
        }
      }
      else {
        let pervisa = getValues3('depositperVisa') || 0
        let amount = getValues3('depositAmount') || 0
        if (pervisa != 0 && amount != 0) {
          console.log(amount);
          console.log(pervisa);
          console.log(amount < pervisa);
          if (!isNaN(Number(amount)) < !isNaN(Number(pervisa))) {
            setDepositError(true)

            let val = Math.floor(getValues3('depositAmount') / getValues3('depositperVisa'));
            setValue3('Payrollforvisa', parseFloat(val))
          }
          else {
            setDepositError(false)
            let val = Math.floor(amount / pervisa);
            setValue3('Payrollforvisa', parseFloat(val))
          }
        }
      }
    }
  }

  const getData = async (formData) => {
    setLoading(true)
    console.log(formData);
    try {
      let params = {
        customer_id: id

      }

      const { data } = await CustomerServices.getCustomerDetail(params);
      console.log(data);
      let details = data?.details
      setCustomerDetails(details)
      setGuarantors(details?.guarantors)
      // setValue('agentName', details?.name)
      // setValue('commissionVisa', details?.commission_visa)
      // setValue('commissionMonthly', details?.commission_monthly)
      setCompanyLogo(details?.logo)
      setValue1('companyLogo', details?.logo)
      setValue1('companyName', details?.name)
      setValue1('businessAddress', details?.address)
      setValue1('businessWebsite', details?.website)
      setValue1('phone', details?.userDetail?.phone)
      setValue1('email', details?.userDetail?.email)
      setValue1('personName', details?.cp_name)
      setValue1('mobile', details?.cp_mobile)
      setValue1('vat', details?.vat_no)

      // setValue2('guaranator', details?.guarantor_name)
      // setValue2('guaranatorNum', details?.guarantor_number)
      setValue3('payroll', details?.payroll_percentage)
      setValue3('depositperVisa', details?.deposit_per_visa)
      setDepositBalance(parseFloat(details?.deposit_total) - parseFloat(details?.deposit_balance))
      console.log(parseFloat(details?.visa_eligibility) - parseFloat(details?.visa_eligibility_remaining), 'asdasds3333');

      setEligibilityBalance(parseFloat(details?.visa_eligibility) - parseFloat(details?.visa_eligibility_remaining))
      if (details?.security_deposit_scenario == 'visa') {
        setValue3('depositAmount', details?.deposit_total)
        setValue3('Payrollforvisa', parseFloat(details?.visa_eligibility))
      }
      else {
        setValue3('depositAmountpayroll', details?.deposit_total)
        setValue3('Payrollforvisa', parseFloat(details?.payroll_eligibility))
      }
      setSelectedAgent(details?.agent)
      setValue5('agent', details?.agent)
      setValue5('commissionMonthly', details?.agent_commission_monthly)

      setValue5('commission', details?.agent_commission_visa)
      setValue6('1yearInsideRate', details?.one_year_inside)
      setValue6('1yearOutsideRate', details?.one_year_outside)
      setValue6('1yearRenewalRates', details?.one_year_renewal)
      setValue6('2yearInsideRate', details?.two_year_inside)
      setValue6('absconderFee', details?.absconder_fee)
      setValue6('2yearOutsideRate', details?.two_year_outside)
      setValue6('2yearRenewalRates', details?.two_year_renewal)
      setValue6('monthlyVisaServiceCharges', details?.monthly_visa_service_below)
      setValue6('monthlyVisaServiceChargesabove', details?.monthly_visa_service_above)
      console.log(details?.monthly_visa_service, 'details?.monthly_visa_service');
      if (details?.monthly_visa_service_below && details?.monthly_visa_service_above) {
        setMonthlyChargesCheck(true)
      }
      if (details?.medical_extra > 0) {
        setVipMedicalCheck(true)
      }
      setValue6('medicalCharges', details?.medical_extra)
      setValue6('cancellationRates', details?.cancellation)
      setValue6('rejectionCost', details?.rejection)
      setValue6('overstayCost', details?.overstay)
      setSelectedDue({ id: details?.due_date_policy, name: details?.due_date_policy })
      setValue6('Due', { id: details?.due_date_policy, name: details?.due_date_policy })
      setradiovalue(details?.security_deposit_scenario)
      const cash = details?.deposits?.filter(payment => payment.payment_type === 'cash');
      const cheque = details?.deposits?.filter(payment => payment.payment_type === 'cheque');
      setDepositList(cash)
      setDepositChequesList(cheque)
      setAllocation(details?.open_allocation)
      setDocuments(details?.documents)
      console.log(details?.documents);
      const passportData = details?.documents?.find(item => item.key == 'passport');
      const licenseData = details?.documents?.find(item => item.key == 'tradeLicense');
      const emiratesIds = details?.documents?.find(item => item.key == 'emirateIds');
      const agreementData = details?.documents?.find(item => item.key == 'Agreement');
      const otherDocData = details?.documents?.find(item => item.key == 'otherdoc');
      const ventureagreementData = details?.documents?.find(item => item.key == 'ventureagreement');
      const guaranatorIdData = details?.documents?.find(item => item.key == 'guaranatorEmirateId');
      const attorneyData = details?.documents?.find(item => item.key == 'attorney');
      const MoAData = details?.documents?.find(item => item.key == 'MoA');
      const VatData = details?.documents?.find(item => item.key == 'VAT');
      const guaranatorEmirateIdData = details?.documents?.find(item => item.key == 'guaranatorEmirateId');
      const guaranatorPassportData = details?.documents?.find(item => item.key == 'guaranatorPassport');
      console.log(passportData);
      if (passportData) {
        setPassport(passportData?.path);
        setPassportWithExpiry(handelDate(passportData?.expiry_date))
        setValue4('passportExp', handelDate(passportData?.expiry_date))
      }
      if (licenseData) {
        setTradeLiscense(licenseData?.path);
        setTradeLicenseWithExpiry(handelDate(licenseData?.expiry_date))
        setValue4('LiscenseExp', handelDate(licenseData?.expiry_date))
      }
      if (emiratesIds) {
        setEmirateIds(emiratesIds?.path);
        setEmirateIdsWithExpiry(handelDate(emiratesIds?.expiry_date))
        setValue4('emirateIDsExpiry', handelDate(emiratesIds?.expiry_date))
      }
      if (agreementData) {
        setAgreement(agreementData?.path);
        setAgreementWithExpiry(handelDate(agreementData?.expiry_date))
        setValue4('agreementExpiry', handelDate(agreementData?.expiry_date))
      }
      if (ventureagreementData) {
        setVentureAgreement(ventureagreementData?.path);
        setVentureAgreementWithExpiry(handelDate(ventureagreementData?.expiry_date))
        setValue4('VentureagreementExpiry', handelDate(ventureagreementData?.expiry_date))
      }
      // if (guaranatorIdData) {
      //   console.log(guaranatorIdData, 'guaranatorIdDataguaranatorIdData');
      //   setEmirateId(guaranatorIdData?.path);
      //   setEmirateIdExpiryDate(handelDate(guaranatorIdData?.expiry_date))
      //   setValue2('emirateExp', handelDate(guaranatorIdData?.expiry_date))
      // }
      if (attorneyData) {
        setAttorney(attorneyData?.path);

      }
      if (MoAData) {
        setMoA(MoAData?.path);

      }
      if (VatData) {
        setVat(VatData?.path);

      }
      // if (guaranatorPassportData) {
      //   setGuaranatorPassport(guaranatorPassportData?.path);
      //   setValue2('guaranatorPassport', guaranatorPassportData?.path)
      //   setGuranaratorPassportExpiryDate(handelDate(guaranatorPassportData?.expiry_date))
      //   setValue2('passportExp', handelDate(guaranatorPassportData?.expiry_date))
      //   setValue2('guaranatorPassportExp', handelDate(guaranatorPassportData?.expiry_date))
      // }
      // if (guaranatorEmirateIdData) {
      //     setEmirateId(guaranatorEmirateIdData?.path);
      //     setEmirateIdExpiryDate(handelDate(guaranatorEmirateIdData?.expiry_date))
      //     setValue2('emirateExp', handelDate(guaranatorEmirateIdData?.expiry_date))
      // }
      // handleDocArrayUpdate('path', '', 'guaranatorPassport');
      // handleDocArrayUpdate('path', '', 'guaranatorEmirateId');
      // console.log(documents,'asdasdasdasdas');


      if (otherDocData) {
        setOtherDoc(otherDocData?.path);

      }

    } catch (error) {

    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getAgents()
    getData()
  }, [])
  useEffect(() => {
    let combinedArray = [...depositList, ...depositChequesList]
    console.log(combinedArray);
    const totalAmount = combinedArray?.reduce((total, payment) => total + parseFloat(payment.amount), 0);
    setDepositTotal(totalAmount)
  }, [depositChequesList, depositList])


  return (
    <>
      <Box sx={{ width: "100%" }}>


      </Box>
      <Box >
        {activeStep === 1 && <>

          <Box component={'form'} onSubmit={handleSubmit1(submitForm1)}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', p: 3, alignItems: 'flex-end' }}>
              <Typography sx={{ fontSize: "22px", fontWeight: 'bold' }} > {url == 'Customer Profile' ? "PROFILE" : "UPDATE CUSTOMER"}  </Typography>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <PrimaryButton
                  bgcolor={Colors.buttonBg}
                  title="Previous"
                  onClick={handleBack} disabled={activeStep === 1}

                />
                <PrimaryButton
                  bgcolor={Colors.buttonBg}
                  title="Next"
                  type={'submit'}
                  disabled={!emailVerify ? true : false}

                />
              </Box>
            </Box>
            <MobileStepper
              variant="progress"
              steps={7} className='custom-stepper'

              position="static"
              activeStep={activeStep}
              sx={{ width: '100%', }}
            ></MobileStepper>
            <Box sx={{ p: 3 }}>
              <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 2 }}>Company Information: </Typography>
              <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Upload Company Logo:</Typography>
              <UploadFileSingle
                Memo={true}
                accept={allowFilesType2}
                file={companyLogo}
                error={errors1?.companyLogo?.message}
                register={register1("companyLogo", {
                  required: false,
                  onChange: async (e) => {
                    const path = await handleUploadDocument(e);
                    console.log(path,'path');
                    
                      setCompanyLogo(path);
                    
                  }
                })}
              />

              <Grid container sx={{ gap: '5px 25px' }}>
                <Grid item xs={5}>
                  <InputField
                    label={"Company Name :*"}
                    size={'small'}
                    placeholder={"Comapny Name"}
                    error={errors1?.companyName?.message}
                    register={register1("companyName", {
                      required:
                        "Please enter your comapny name."

                    })}
                  /></Grid>
                <Grid item xs={5}><InputField
                  label={"Business Adddress :*"}
                  size={'small'}
                  placeholder={"Business Adddress"}
                  error={errors1?.businessAddress?.message}
                  register={register1("businessAddress", {
                    required:
                      "Please enter your business address."

                  })}
                /></Grid>
                <Grid item xs={5}><InputField
                  label={"Business Website :"}
                  size={'small'}
                  placeholder={"Business Website"}
                  error={errors1?.businessWebsite?.message}
                  register={register1("businessWebsite", {
                    required:
                      false

                  })}
                /></Grid>
                <Grid item xs={5}><InputField
                  label={"Phone :*"}
                  size={'small'}
                  type={'number'}
                  placeholder={"Phone"}
                  error={errors1?.phone?.message}
                  register={register1("phone", {
                    required:
                      "Please enter your phone number.",
                    pattern: {
                      value: /^05[0-9]{8}$/,
                      message: "Please enter a valid UAE phone number (starting with 05 and 8 digits)."
                    }

                  })}
                /></Grid>
                <Grid item xs={5}>
                  <InputField
                    label={"Email :*"}
                    size={"small"}
                    placeholder={"Email"}
                    disabled={user?.user_type == 'C' ? true : false}
                    error={errors1?.email?.message}
                    register={register1("email", {
                      required: "Please enter your email.",
                      onChange: (e) => {
                        console.log('asdas');

                        Debounce2(() => verifyEmail());
                        // Delay the execution of verifyEmail by 2 seconds

                      },
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please enter a valid email address."
                      },

                    })}
                  />
                </Grid>
                <Grid item xs={5}><InputField
                  label={"Contact Person Name :*"}
                  size={'small'}
                  placeholder={"Contact Person Name"}
                  error={errors1?.personName?.message}
                  register={register1("personName", {
                    required:
                      "Please enter your contact person name."

                  })}
                /></Grid>
                <Grid item xs={5}><InputField
                  label={"Mobile :*"}
                  size={'small'}
                  type={'number'}
                  placeholder={"Mobile"}
                  error={errors1?.mobile?.message}
                  register={register1("mobile", {
                    required:
                      "Please enter your mobile.",
                    pattern: {
                      value: /^05[0-9]{8}$/,
                      message: "Please enter a valid UAE phone number (starting with 05 and 8 digits)."
                    }

                  })}
                /></Grid>
                <Grid item xs={5}>
                  <InputField
                    label={"VAT Number :*"}
                    type={'number'}
                    size={'small'}
                    placeholder={"VAT Number"}
                    error={errors1?.vat?.message}
                    register={register1("vat", {
                      required: "Please enter your VAT number.",
                      minLength: {
                        value: 15,
                        message: "VAT number must be exactly 15 digits."
                      },
                      maxLength: {
                        value: 15,
                        message: "VAT number must be exactly 15 digits."
                      },
                    })}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box></>}
        {activeStep === 2 && <Box component={'form'} onSubmit={handleSubmit2(submitForm2)}>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', p: 3, alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: "22px", fontWeight: 'bold' }} >{url == 'Customer Profile' ? "PROFILE" : "UPDATE CUSTOMER"} </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Previous"
                onClick={handleBack} disabled={activeStep === 1}

              />
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Next"
                onClick={handleNext}
                disabled={guarantors.length == 0 || activeStep === 6}

              />
            </Box>
          </Box>
          <MobileStepper
            variant="progress"
            steps={7} className='custom-stepper'

            position="static"
            activeStep={activeStep}
            sx={{ width: '100%' }}
          ></MobileStepper>
          <Box sx={{ p: 3 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 2 }}>Guarantor Information: </Typography>

            <Grid container sx={{ gap: '5px 25px' }}>
              <Grid item xs={5}><InputField
                label={"Guarantor Name :*"}
                size={'small'}
                placeholder={"Guarantor Name"}
                error={errors2?.guaranator?.message}
                register={register2("guaranator", {
                  required:
                    "Please enter your Guarantor name."

                })}
              /></Grid>
              <Grid item xs={5}><InputField
                label={"Guarantor Number :*"}
                size={'small'}
                type={'number'}
                placeholder={"Guarantor Number "}
                error={errors2?.guaranatorNum?.message}
                register={register2("guaranatorNum", {
                  required:
                    "Please enter your Guarantor number.",
                  pattern: {
                    value: /^05[0-9]{8}$/,
                    message: "Please enter a valid UAE phone number (starting with 05 and 8 digits)."
                  }

                })}
              /></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Upload Guarantor Passport:*</Typography>
                <UploadFile
                  Memo={true}
                  accept={allowFilesType}
                  file={documents}
                  multiple={true}
                  updateResult={updateResult}
                  fileId={'guaranatorPassport'}
                  error={errors2?.guaranatorPassport?.message}
                  loader={loader}
                  disabled={isUploading} // Disable while uploading
                  register={register2(`guaranatorPassport`, {
                    required: documents.find((item => item?.key == 'guaranatorPassport'))?.path != '' ? false :
                      "Please upload your  guaranatorPassport.",
                    onChange: async (e) => {
                      setIsUploading(true); // Set uploading to true when the upload starts
                      const path = await handleUploadDocument(e, "guaranatorPassport");
                      if (path) {
                        handleDocArrayUpdate('path', path, 'guaranatorPassport');
                        setGuaranatorPassport(path)
                        console.log(path);
                      }
                      setIsUploading(false); // Reset uploading status when done
                    }
                  })}
                />

              </Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Upload Guarantor Emirates ID:* </Typography>
                <UploadFile
                  Memo={true}
                  accept={allowFilesType}
                  file={documents}
                  multiple={true}
                  updateResult={updateResult}
                  fileId={'guaranatorEmirateId'}
                  error={errors2?.guaranatorEmirateId?.message}
                  loader={loader}
                  disabled={isUploading} // Disable while uploading
                  register={register2(`guaranatorEmirateId`, {
                    required: documents.find((item => item?.key == 'guaranatorEmirateId'))?.path != '' ? false :
                      "Please upload your  guaranatorEmirateId.",
                    onChange: async (e) => {
                      setIsUploading(true); // Set uploading to true when the upload starts
                      const path = await handleUploadDocument(e, "guaranatorEmirateId");
                      if (path) {
                        setGuaranatorEmirateId(path)
                        handleDocArrayUpdate('path', path, 'guaranatorEmirateId');
                        console.log(path);
                      }
                      setIsUploading(false); // Reset uploading status when done
                    }
                  })}
                />

              </Grid>
              <Grid item xs={5}>

                <DatePicker
                  label={"Guarantor Passport Expiry Date :*"}
                  value={guranaratorPassportExpiryDate}
                  disablePast={true}
                  size={'small'}
                  minDate={addMonths(new Date(), 6)}
                  error={errors2?.passportExp?.message}
                  register={register2("passportExp", {
                    required:
                      guranaratorPassportExpiryDate ? false :
                        "please enter your passport expiry date."

                  })}
                  onChange={(date) => {
                    handlepassportDate(date)
                    setValue2('passportExp', date)
                    handleDocArrayUpdate('date', new Date(date), 'guaranatorPassport')
                  }

                  }
                /></Grid>
              <Grid item xs={5}>
                <DatePicker
                  label={"Guarantor Emirate ID Expiry Date :*"}
                  value={emirateIdExpiryDate}
                  size={'small'}
                  disablePast={true}
                  error={errors2?.emirateExp?.message}
                  register={register2("emirateExp", {
                    required:
                      emirateIdExpiryDate ? false :
                        "please enter your emirate expiry date."

                  })}
                  onChange={(date) => {
                    handleEmirateDate(date)
                    setValue2('emirateExp', date)
                    handleDocArrayUpdate('date', new Date(date), 'guaranatorEmirateId')
                  }

                  }
                /></Grid>
              <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box >
                  <PrimaryButton
                    bgcolor={Colors.buttonBg}
                    title="Add Guarantor"
                    type={'submit'}
                    disabled={activeStep === 6}


                  />
                </Box>
              </Grid>

            </Grid>

          </Box>

          {guarantors?.length > 0 && <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 2, m: 3 }}>Guarantors : </Typography>}
          <Box m={3}>
            <Grid container spacing={2} >


              {guarantors?.length > 0 && guarantors?.map((item, index) => (

                <Grid item xs={6} >
                  <Box
                    component={'div'}

                    sx={{
                      position: 'relative',
                      border: `2px solid ${Colors.primary}`,
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      padding: '16px',
                      borderRadius: '8px',
                      maxWidth: '100%',
                      margin: '0 auto',
                      backgroundColor: '#fff',
                      fontFamily: 'Public Sans'
                    }}
                  >
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px'
                      }}
                      aria-label="delete"
                      onClick={() => handleDelete(item?.order_by)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Typography variant="body1" gutterBottom>
                      <strong>Name:</strong>&nbsp; {item?.name}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Number:</strong>&nbsp;{item?.number}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <strong>Guarantor Passport :</strong>&nbsp;
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <span>Expiry Date :</span>&nbsp;{moment(item?.passport_expiry).format('MM-DD-YYYY')}
                    </Typography>
                    <Box component={'div'} sx={{ width: '30%' }}>
                      {item?.passport_documents?.split(',').map((item, index) => (
                        <Box
                          key={index}
                          onClick={() => {
                            if (item) {
                              console.log(item, 'asdasd');
                              const fileType = item.split('_').pop();
                              if (fileType.includes('doc') || fileType.includes('xls')) {
                                handleDownload(item, fileType);
                              } else {
                                window.open(process.env.REACT_APP_IMAGE_BASE_URL + item, '_blank');
                              }
                            }
                          }}
                        >
                          <Box>
                            {item ? (
                              <Box component={'img'} src={Images.docIcon} width={'35px'} />
                            ) : (
                              <DoDisturbIcon sx={{ fontSize: '35px', color: 'gray' }} />
                            )}
                          </Box>
                          <Link rel="noopener noreferrer">
                            {item.split('_').pop()}
                          </Link>
                        </Box>
                      ))}
                    </Box>
                    <Typography variant="body1" gutterBottom mt={2}>
                      <strong>Guarantor Emirates Id :</strong>&nbsp;
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <span>Expiry Date :</span>&nbsp;{moment(item?.emirate_expiry).format('MM-DD-YYYY')}
                    </Typography>
                    <Box component={'div'} sx={{ width: '30%' }}>
                      {item?.emirate_documents?.split(',').map((item, index) => (
                        <Box
                          key={index}
                          onClick={() => {
                            if (item) {
                              console.log(item, 'asdasd');
                              const fileType = item.split('_').pop();
                              if (fileType.includes('doc') || fileType.includes('xls')) {
                                handleDownload(item, fileType);
                              } else {
                                window.open(process.env.REACT_APP_IMAGE_BASE_URL + item, '_blank');
                              }
                            }
                          }}
                        >
                          <Box>
                            {item ? (
                              <Box component={'img'} src={Images.docIcon} width={'35px'} />
                            ) : (
                              <DoDisturbIcon sx={{ fontSize: '35px', color: 'gray' }} />
                            )}
                          </Box>
                          <Link rel="noopener noreferrer">
                            {item.split('_').pop()}
                          </Link>
                        </Box>
                      ))}
                    </Box>



                  </Box>
                  {/* <Chip
        label={item?.name}
        onClick={() => {
            handleUpdateCandidate(item, index)

        }}
        onDelete={() => handleDelete(index)}
        deleteIcon={<DeleteIcon />}
        variant="outlined"
    /> */}


                </Grid>


              ))}

            </Grid>
          </Box>
        </Box>}
        {activeStep === 3 && <Box component={'form'} onSubmit={handleSubmit3(submitForm3)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', p: 3, alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: "22px", fontWeight: 'bold' }} >{url == 'Customer Profile' ? "PROFILE" : "UPDATE CUSTOMER"} </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Previous"
                onClick={handleBack} disabled={activeStep === 1}

              />
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Next"
                type={'submit'}
                disabled={activeStep === 6 || depositError}

              />
            </Box>
          </Box>
          <MobileStepper
            variant="progress"
            steps={7} className='custom-stepper'

            position="static"
            activeStep={activeStep}
            sx={{ width: '100%' }}
          ></MobileStepper>
          <Box sx={{ p: 3 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors?.textColorDarkBlue, mb: 2, mt: 2 }}>Security Deposits: </Typography>


            <Grid container sx={{ gap: '5px 25px' }}>

              <Grid item xs={12} sm={12}>
                <Typography sx={{ fontSize: '15px', color: Colors.black, mb: 2 }}>Security Deposit Scenario : </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue={radiovalue}
                    onChange={(e) => {
                      setradiovalue(e.target.value);
                      setValue('cash','')
                      setValue('chequeAmount','')
                      setValue3('depositAmount', '')
                      setValue3('Payrollforvisa', '')
                    }}
                  >
                    <FormControlLabel
                      sx={{ color: "#000" }}
                      value="payroll"
                      control={<Radio />}
                      label="Payroll"
                    />
                    <FormControlLabel
                      sx={{ color: "#000" }}
                      value="visa"
                      control={<Radio />}
                      label="Visa"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>


              {radiovalue != 'visa' && <Grid container sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={5} >
                  <LabelCustomInput label={'Payroll :* '} StartLabel={'%'} placeholder={'Enter Percentage'} error={errors3?.payroll?.message} disabled={radiovalue == 0 ? true : false} register={register3("payroll", {
                    required: "Enter payroll.",
                    onChange: (e) => {
                      if (e.target.value > 0) {
                        eglibilityCalc('payroll')
                      }

                    },
                  })} />
                </Grid>
              </Grid>}
              {radiovalue != 'payroll' && <Grid item xs={5} >
                <LabelCustomInput label={'Security Deposite Per Visa :* '} StartLabel={'AED'} error={errors3.depositperVisa?.message}
                  register={register3("depositperVisa", {
                    required: "Enter deposit per Visa.",
                    onChange: (e) => eglibilityCalc(),
                  })} postfix={true} />
              </Grid>}
              {radiovalue != 'payroll' ? <Grid item xs={5} >
                <LabelCustomInput
                  label={'Security Deposit Amount :* '}
                  StartLabel={'AED'}
                  step={'1'}
                  error={errors3?.depositAmount?.message}
                  register={register3("depositAmount", {
                    required: "Enter deposit amount.",
                    onChange: (e) => eglibilityCalc(),
                  })}
                />
                <span style={{ color: 'red', fontSize: '12px' }}>{depositError ? 'deposit Amount must be greater than security Deposit per Visa' : ''}</span>
              </Grid> :
                <Grid item xs={5} >
                  <LabelCustomInput
                    label={'Security Deposit Amount :* '}
                    StartLabel={'AED'}
                    step={'1'}
                    error={errors3?.depositAmountpayroll?.message}
                    register={register3("depositAmountpayroll", {
                      required: "Enter deposit amount.",
                      onChange: (e) => eglibilityCalc('payroll'),
                    })}
                  />

                </Grid>}
              {true && <Grid container sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={5} >
                  <InputField
                    label={radiovalue == 'visa' ? "Eligibility For Visa :*" : 'Eligibility Payroll For Visa :*'}
                    size={'small'}
                    inputProps={{ readOnly: true }}

                    error={errors3?.Payrollforvisa?.message}
                    register={register3("Payrollforvisa", {
                      required:
                        radiovalue == 0 ? "Please enter your Payroll for visa ." : false


                    })}
                  />
                </Grid>

              </Grid>}
              <Grid container>
                <Grid item xs={5} >
                  <SelectField
                    size={'small'}
                    label={'Select Deposit Type :'}
                    options={[{ id: 'cash', name: 'Cash' }, { id: 'cheque', name: 'Cheque' }]}
                    selected={selectedDesposit}
                    onSelect={(value) => { setSelectedDesposit(value); setValue('cash', ''); setValue('chequeAmount', '') }}
                    error={errors3?.deposit?.message}
                    register={register3("deposit", {
                      required: false
                    })}
                  />
                </Grid>
              </Grid>
              {selectedDesposit?.id == 'cash' ? <>

                <Grid container >
                  <Grid item xs={5} >
                    <LabelCustomInput label={'Cash : '} StartLabel={'AED'} register={register("cash", { required: "Enter cash" })} />
                  </Grid>
                  <Grid item xs={5} display={'flex'} justifyContent={'center'} alignItems={'flex-end'} >
                    <PrimaryButton
                      bgcolor={Colors.buttonBg}
                      title="Add"
                      disabled={addbutton}
                      onClick={() => AddDeposits(getValues('cash'), 'cash')}

                    />
                  </Grid>
                </Grid></> :
                <>
                  <Grid container>
                    <Grid item xs={5} >
                      <LabelCustomInput label={'Amount : '} min={0} StartLabel={'AED'} register={register(`chequeAmount`, { required: "Enter cheque Amount" })} />
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={5} >
                    <LabelCustomInput label={'Cheque Number : '} register={register(`chequeNum`, { required: "Enter cheque Number" })} StartLabel={'AED'} />
                  </Grid> */}
                  <Grid item xs={5}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray, mt: 1 }}>Upload Cheque Image: </Typography>
                    <UploadFileSingle
                      Memo={true}
                      accept={allowFilesType}
                      file={chequeImage}
                      error={errors?.chequeImage?.message}
                      register={register("chequeImage", {
                        required:

                          "Please upload your cheque image  ."
                        ,
                        onChange: async (e) => {
                          const path = await handleUploadDocument(e);
                          if (path) {
                            setChequeImage(path);
                          }
                        }
                      })}
                    />

                  </Grid>
                  <Grid item xs={5} display={'flex'} justifyContent={'flex-start'} alignItems={'center'} >
                    <PrimaryButton
                      bgcolor={Colors.buttonBg}
                      title="Add"
                      disabled={addbutton}
                      onClick={() => AddDepositCheques(getValues('chequeAmount'), 'cheque')}

                    />
                  </Grid></>
              }
              {(depositList?.length > 0 || depositChequesList?.length > 0) && <Grid container>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 1 }}>Security Deposits: </Typography>
              </Grid>}
              {depositList?.length > 0 && depositList?.map((item, index) => (

                <Grid container >
                  {parseFloat(item?.consumed) == 0 && <span onClick={() => deleteDeposit(index)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '30px' }}>
                    <DeleteIcon />
                  </span>}
                  <Grid item xs={5} >
                    <InputField
                      size={"small"}
                      disabled={true}
                      value={item?.amount}
                      label={"Cash :"}
                      placeholder={"Cash"}

                      // error={errors?.auctionHouse?.message}
                      register={register(`cash${index}`)}
                    />

                  </Grid>
                  &nbsp;&nbsp;

                </Grid>
              ))}
              {depositChequesList?.length > 0 && depositChequesList?.map((item, index) => (

                <Grid container spacing={2} alignItems={'center'}>
                  {parseFloat(item?.consumed) == 0 && <span onClick={() => deleteDepositCheque(index)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '30px' }}>
                    <DeleteIcon />
                  </span>}
                  <Grid item xs={5} >
                    <InputField
                      size={"small"}
                      disabled={true}
                      value={item?.amount}
                      label={"Cheque :"}
                      placeholder={"Cheque"}

                      // error={errors?.auctionHouse?.message}
                      register={register(`cheque${index}`)}
                    />

                  </Grid>
                  <Grid
                    item
                    md={6}
                    lg={4}
                    sx={{ cursor: 'pointer' }}
                    component={'div'}
                    onClick={() => handleDownload(item?.cheque_document, item?.cheque_document?.split('_').pop())}
                  // Use index2 instead of index to avoid duplicate keys
                  >
                    <Box sx={{ fontSize: '15px', fontWeight: 'bold' }}>{item?.cheque_document?.split('_').pop()}</Box>
                    <Box>
                      <Box component={'img'} src={Images.docIcon} width={'35px'} />
                    </Box>

                  </Grid>
                  {/* <Grid item xs={5} >
                    {item?.cheque_document && <Box

                      component={"img"}
                      src={
                        process.env.REACT_APP_IMAGE_BASE_URL + item?.cheque_document
                      }
                      sx={{ height: 180, width: "auto" }}
                    />}
                  </Grid> */}
                  &nbsp;&nbsp;

                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>}
        {activeStep === 4 && <Box component={'form'} onSubmit={handleSubmit4(submitForm4)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', p: 3, alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: "22px", fontWeight: 'bold' }} >{url == 'Customer Profile' ? "PROFILE" : "UPDATE CUSTOMER"} </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Previous"
                onClick={handleBack} disabled={activeStep === 1}

              />
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Next"
                type={'submit'}
                disabled={activeStep === 6}

              />
            </Box>
          </Box>
          <MobileStepper
            variant="progress"
            steps={7} className='custom-stepper'

            position="static"
            activeStep={activeStep}
            sx={{ width: '100%' }}
          ></MobileStepper>
          <Box sx={{ p: 3 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 2 }}>Upload Documents: </Typography>

            <Grid container sx={{ gap: '5px 25px' }}>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Trade License:* </Typography>
                <UploadFile
                  Memo={true}
                  accept={allowFilesType}
                  file={documents}
                  multiple={true}
                  updateResult={updateResult}
                  fileId={'tradeLicense'}
                  error={errors4?.tradeLicense?.message}
                  loader={loader}
                  disabled={isUploading} // Disable while uploading
                  register={register4(`tradeLicense`, {
                    required: documents.find((item => item?.key == 'tradeLicense'))?.path != '' ? false :
                      "Please upload your  tradeLicense.",
                    onChange: async (e) => {
                      setIsUploading(true); // Set uploading to true when the upload starts
                      const path = await handleUploadDocument(e, "tradeLicense");
                      if (path) {
                        handleDocArrayUpdate('path', path, 'tradeLicense');
                        console.log(path);
                      }
                      setIsUploading(false); // Reset uploading status when done
                    }
                  })}
                />

              </Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Passport :* </Typography>
                <UploadFile
                  Memo={true}
                  accept={allowFilesType}
                  error={errors4?.passport?.message}
                  file={documents}
                  multiple={true}
                  updateResult={updateResult}
                  fileId={'passport'}
                  loader={loader}
                  disabled={isUploading} // Disable while uploading
                  register={register4(`passport`, {
                    required: documents.find((item => item?.key == 'passport'))?.path != '' ? false :
                      "Please upload your  passport.",
                    onChange: async (e) => {
                      setIsUploading(true); // Set uploading to true when the upload starts
                      const path = await handleUploadDocument(e, "passport");
                      if (path) {
                        handleDocArrayUpdate('path', path, 'passport');
                        console.log(path);
                      }
                      setIsUploading(false); // Reset uploading status when done
                    }
                  })}
                />

              </Grid>
              <Grid item xs={5}><DatePicker
                disablePast={true}
                size={"small"}
                label={"Trade License  Expiry Date :*"}
                value={tradeLicenseWithExpiry}
                error={errors4?.LiscenseExp?.message}
                register={register4("LiscenseExp", {
                  required:

                    "please enter your License expiry date."

                })}
                onChange={(date) => {

                  setValue4('LiscenseExp', date)
                  setTradeLicenseWithExpiry(new Date(date))
                  handleDocArrayUpdate('date', new Date(date), 'tradeLicense')
                }}
              /></Grid>
              <Grid item xs={5}><DatePicker
                disablePast={true}
                size={"small"}
                label={"Passport Expiry Date :*"}
                value={passportWithExpiry}
                minDate={addMonths(new Date(), 6)}
                error={errors4?.passportExp?.message}
                register={register4("passportExp", {
                  required:

                    "please enter your passport expiry date."

                })}
                onChange={(date) => {

                  setValue4('passportExp', date)
                  setPassportWithExpiry(new Date(date))
                  handleDocArrayUpdate('date', new Date(date), 'passport')
                }}
              /></Grid>
              <Grid item xs={5}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Emirate ID's:* </Typography>
                <UploadFile
                  Memo={true}
                  accept={allowFilesType}
                  file={documents}
                  multiple={true}
                  updateResult={updateResult}
                  fileId={'emirateIds'}
                  error={errors4?.emirateIds?.message}
                  loader={loader}
                  disabled={isUploading} // Disable while uploading
                  register={register4(`emirateIds`, {
                    required: documents.find((item => item?.key == 'emirateIds'))?.path != '' ? false :
                      "Please upload your  emirateIds.",
                    onChange: async (e) => {
                      setIsUploading(true); // Set uploading to true when the upload starts
                      const path = await handleUploadDocument(e, "emirateIds");
                      if (path) {
                        handleDocArrayUpdate('path', path, 'emirateIds');
                        console.log(path);
                      }
                      setIsUploading(false); // Reset uploading status when done
                    }
                  })}
                />

              </Grid>

              <Grid item xs={5}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Agreement :* </Typography>
                <UploadFile
                  Memo={true}
                  accept={allowFilesType}
                  file={documents}
                  multiple={true}
                  updateResult={updateResult}
                  fileId={'Agreement'}
                  error={errors4?.Agreement?.message}
                  loader={loader}
                  disabled={isUploading} // Disable while uploading
                  register={register4(`Agreement`, {
                    required: documents.find((item => item?.key == 'Agreement'))?.path != '' ? false :
                      "Please upload your  Agreement.",
                    onChange: async (e) => {
                      setIsUploading(true); // Set uploading to true when the upload starts
                      const path = await handleUploadDocument(e, "Agreement");
                      if (path) {
                        handleDocArrayUpdate('path', path, 'Agreement');
                        console.log(path);
                      }
                      setIsUploading(false); // Reset uploading status when done
                    }
                  })}
                />

              </Grid>
              <Grid item xs={5}><DatePicker
                disablePast={true}
                size={"small"}
                label={"Emirate ID's  Expiry Date :*"}
                value={emirateIdsWithExpiry}
                error={errors4?.emirateIDsExpiry?.message}
                register={register4("emirateIDsExpiry", {
                  required:

                    "please enter your emirate id's expiry date."

                })}
                onChange={(date) => {

                  setValue4('emirateIDsExpiry', date)
                  setEmirateIdsWithExpiry(new Date(date))
                  handleDocArrayUpdate('date', new Date(date), 'emirateIds')
                }}
              /></Grid>
              <Grid item xs={5}><DatePicker
                disablePast={true}
                size={"small"}
                label={"Agreement Expiry Date :*"}
                value={agreementWithExpiry}
                error={errors4?.agreementExpiry?.message}
                register={register4("agreementExpiry", {
                  required:

                    "please enter your agreement expiry date."

                })}
                onChange={(date) => {

                  setValue4('agreementExpiry', date)
                  setAgreementWithExpiry(new Date(date))
                  handleDocArrayUpdate('date', new Date(date), 'Agreement')
                }}
              /></Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Joint Venture Agreement : </Typography>
                <UploadFile
                  Memo={true}
                  accept={allowFilesType}
                  file={documents}
                  multiple={true}
                  updateResult={updateResult}
                  fileId={'ventureagreement'}
                  error={errors4?.ventureagreement?.message}
                  loader={loader}
                  disabled={isUploading} // Disable while uploading
                  register={register4(`ventureagreement`, {
                    required: false,
                    onChange: async (e) => {
                      setIsUploading(true); // Set uploading to true when the upload starts
                      const path = await handleUploadDocument(e, "ventureagreement");
                      if (path) {
                        handleDocArrayUpdate('path', path, 'ventureagreement');
                        console.log(path);
                      }
                      setIsUploading(false); // Reset uploading status when done
                    }
                  })}
                />

              </Grid>
              <Grid item xs={5}><DatePicker
                disablePast={true}
                size={"small"}
                label={"Joint Venture Agreement Expiry Date :"}
                value={ventureAgreementWithExpiry}
                error={errors4?.VentureagreementExpiry?.message}
                register={register4("VentureagreementExpiry", {
                  required:

                    false

                })}
                onChange={(date) => {
                  console.log(date, 'datedate');

                  setValue4('VentureagreementExpiry', date)
                  setVentureAgreementWithExpiry(new Date(date))
                  if (date != null) {

                    handleDocArrayUpdate('date', new Date(date), 'ventureagreement')
                  }
                  else {
                    handleDocArrayUpdate('date', null, 'ventureagreement')
                  }
                }}
              /></Grid>
              <Grid container>

                <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 1 }}>Other Documents: </Typography>
              </Grid>
              <Grid container>

                <Grid item xs={5}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Power of Attorney : </Typography>
                  <UploadFile
                    Memo={true}
                    accept={allowFilesType}
                    file={documents}
                    multiple={true}
                    updateResult={updateResult}
                    fileId={'attorney'}
                    error={errors4?.attorney?.message}
                    loader={loader}
                    disabled={isUploading} // Disable while uploading
                    register={register4(`attorney`, {
                      required: false,
                      onChange: async (e) => {
                        setIsUploading(true); // Set uploading to true when the upload starts
                        const path = await handleUploadDocument(e, "attorney");
                        if (path) {
                          handleDocArrayUpdate('path', path, 'attorney');
                          console.log(path);
                        }
                        setIsUploading(false); // Reset uploading status when done
                      }
                    })}
                  />

                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>MoA : </Typography>
                  <UploadFile
                    Memo={true}
                    accept={allowFilesType}
                    file={documents}
                    multiple={true}
                    updateResult={updateResult}
                    fileId={'MoA'}
                    error={errors4?.MoA?.message}
                    loader={loader}
                    disabled={isUploading} // Disable while uploading
                    register={register4(`MoA`, {
                      required: false,
                      onChange: async (e) => {
                        setIsUploading(true); // Set uploading to true when the upload starts
                        const path = await handleUploadDocument(e, "MoA");
                        if (path) {
                          handleDocArrayUpdate('path', path, 'MoA');
                          console.log(path);
                        }
                        setIsUploading(false); // Reset uploading status when done
                      }
                    })}
                  />

                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>VAT Certificate : </Typography>
                  <UploadFile
                    Memo={true}
                    accept={allowFilesType}
                    file={documents}
                    multiple={true}
                    updateResult={updateResult}
                    fileId={'VAT'}
                    error={errors4?.VAT?.message}
                    loader={loader}
                    disabled={isUploading} // Disable while uploading
                    register={register4(`VAT`, {
                      required: false,
                      onChange: async (e) => {
                        setIsUploading(true); // Set uploading to true when the upload starts
                        const path = await handleUploadDocument(e, "VAT");
                        if (path) {
                          handleDocArrayUpdate('path', path, 'VAT');
                          console.log(path);
                        }
                        setIsUploading(false); // Reset uploading status when done
                      }
                    })}
                  />

                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.gray }}>Other Documents : </Typography>
                  <UploadFile
                    Memo={true}
                    accept={allowFilesType}
                    file={documents}
                    multiple={true}
                    updateResult={updateResult}
                    fileId={'otherdoc'}
                    error={errors4?.otherdoc?.message}
                    loader={loader}

                    disabled={isUploading} // Disable while uploading
                    register={register4(`otherdoc`, {
                      required: false,
                      onChange: async (e) => {
                        setIsUploading(true); // Set uploading to true when the upload starts
                        const path = await handleUploadDocument(e, "otherdoc");
                        if (path) {
                          handleDocArrayUpdate('path', path, 'otherdoc');
                          console.log(path);
                        }
                        setIsUploading(false); // Reset uploading status when done
                      }
                    })}
                  />

                </Grid>
              </Grid>

            </Grid>
          </Box>
        </Box>}
        {activeStep === 5 && <Box component={'form'} onSubmit={handleSubmit5(submitForm5)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', p: 3, alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: "22px", fontWeight: 'bold' }} >{url == 'Customer Profile' ? "PROFILE" : "UPDATE CUSTOMER"} </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Previous"
                onClick={handleBack} disabled={activeStep === 1}

              />
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Next"
                type={'submit'}
                disabled={activeStep === 6}

              />
            </Box>
          </Box>
          <MobileStepper
            variant="progress"
            steps={7} className='custom-stepper'

            position="static"
            activeStep={activeStep}
            sx={{ width: '100%' }}
          ></MobileStepper>
          <Box sx={{ p: 3 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 2 }}>Agent Allocation: </Typography>


            <Grid container sx={{ gap: '5px 25px' }}>

              <Grid item xs={12} sm={12}>
                <Typography sx={{ fontSize: '15px', color: Colors.black, mb: 2 }}>Open Allocation: </Typography>
                <FormControl>
                  <RadioGroup
                    row
                    defaultValue={allocation}
                    onChange={(e) => {
                      const value = e.target.value === 'true'; // Convert the string "true" or "false" to a boolean
                      setAllocation(value);
                      console.log(value);
                    }}
                  >
                    <FormControlLabel
                      sx={{ color: "#000" }}
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      sx={{ color: "#000" }}
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>

                </FormControl>
              </Grid>
              {console.log(allocation)}
              <Grid item xs={5} >
                <SelectField
                  size={'small'}
                  label={'Select Agent :*'}
                  disabled={allocation ? false : true}
                  options={agents}
                  selected={selectedAgent}
                  onSelect={(value) => {
                    setSelectedAgent(value)
                    setValue5('commission', value.commission_visa)
                    setValue5('commissionMonthly', value.commission_monthly)


                  }}
                  error={errors5?.agent?.message}
                  register={register5("agent", {
                    required: allocation ? 'Please select agent account.' : false,
                  })}
                />
              </Grid>

              <Grid item xs={5} >
                <LabelCustomInput label={'Agent Commission Visa:* '} max={100} StartLabel={'%'} error={errors5?.commission?.message} register={register5("commission", { required: allocation ? 'enter visa commission .' : false })} disabled={allocation ? false : true} />
              </Grid>
              <Grid item xs={5} >
                <LabelCustomInput label={'Agent Commission Monthly:* '} max={100} StartLabel={'%'} error={errors5?.commissionMonthly?.message} register={register5("commissionMonthly", { required: allocation ? 'enter visa commission monthly.' : false })} disabled={allocation ? false : true} />
              </Grid>

            </Grid>
          </Box>
        </Box>}
        {activeStep === 6 && <Box component={'form'} onSubmit={handleSubmit6(submitForm6)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', p: 3, alignItems: 'flex-end' }}>
            <Typography sx={{ fontSize: "22px", fontWeight: 'bold' }} >{url == 'Customer Profile' ? "PROFILE" : "UPDATE CUSTOMER"} </Typography>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Previous"
                onClick={handleBack} disabled={activeStep === 1}

              />
              <PrimaryButton
                bgcolor={Colors.buttonBg}
                title="Save"
                type={'submit'}


              />
            </Box>
          </Box>
          <MobileStepper
            variant="progress"
            steps={7} className='custom-stepper'

            position="static"
            activeStep={activeStep}
            sx={{ width: '100%' }}
          ></MobileStepper>
          <Box sx={{ p: 3 }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 2 }}>Visa Rates : </Typography>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2 }}>1 Year Rates : </Typography>
            <Grid container sx={{ gap: '20px 25px' }}>
              <Grid item xs={5} >
                <SelectField
                  size={'small'}
                  label={'Customer Due Date Policy :*'}
                  disabled={user?.user_type == 'C'}
                  options={[{ id: 'Instant', name: 'Instant' }, { id: '7 days', name: '7 days' }, { id: '15 days', name: '15 days' }, { id: '20 days', name: '20 days' }, { id: '25 days', name: '25 days' }, { id: '30 days', name: '30 days' }, { id: '60 days', name: '60 days' }, { id: '90 days', name: '90 days' }]}
                  selected={selectedDue}
                  onSelect={(value) => {
                    setSelectedDue(value)
                    setValue6('Due', value)


                  }}
                  error={errors6?.Due?.message}
                  register={register6("Due", {
                    required: 'Please select Customer Due.',
                  })}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ gap: '20px 25px' }}>

              <Grid item xs={5} >
                <LabelCustomInput label={'Year Inside Rates :* '} disabled={user?.user_type == 'C' ? true : false} StartLabel={'AED'} register={register6("1yearInsideRate", { required: "Enter year inside rate" })} error={errors6['1yearInsideRate']?.message}  postfix={user?.user_type == 'C' ? false : true} />
              </Grid>
              <Grid item xs={5} >
                <LabelCustomInput label={'Year Outside Rates :* '} disabled={user?.user_type == 'C' ? true : false} StartLabel={'AED'} register={register6("1yearOutsideRate", { required: "Enter year outside rate" })} error={errors6['1yearOutsideRate']?.message} postfix={user?.user_type == 'C' ? false : true} />
              </Grid>
              <Grid item xs={5} >
                <LabelCustomInput label={'Renewal Rates :* '} disabled={user?.user_type == 'C' ? true : false} StartLabel={'AED'} register={register6("1yearRenewalRates", { required: "Enter renewal rate" })} error={errors6['1yearRenewalRates']?.message} postfix={user?.user_type == 'C' ? false : true} />
              </Grid>



            </Grid>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 3 }}>2 Year Rates : </Typography>

            <Grid container sx={{ gap: '20px 25px' }}>

              <Grid item xs={5} >
                <LabelCustomInput label={'Year Inside Rates :* '} disabled={user?.user_type == 'C' ? true : false} StartLabel={'AED'} register={register6("2yearInsideRate", { required: "Enter year inside rate" })} error={errors6['2yearInsideRate']?.message} postfix={user?.user_type == 'C' ? false : true} />
              </Grid>
              <Grid item xs={5} >
                <LabelCustomInput label={'Year Outside Rates :* '} disabled={user?.user_type == 'C' ? true : false} StartLabel={'AED'} register={register6("2yearOutsideRate", { required: "Enter year outside rate" })} error={errors6['2yearOutsideRate']?.message} postfix={user?.user_type == 'C' ? false : true} />
              </Grid>
              <Grid item xs={5} >
                <LabelCustomInput label={'Renewal Rates :* '} disabled={user?.user_type == 'C' ? true : false} StartLabel={'AED'} register={register6("2yearRenewalRates", { required: "Enter renewal rate" })}  error={errors6['2yearRenewalRates']?.message}  postfix={user?.user_type == 'C' ? false : true} />
              </Grid>



            </Grid>
            <Grid container mt={3}>
            <Grid item xs={5} mt={3}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  
                  <LabelCustomInput
                    label="VIP Medical Extra Charges : "
                    StartLabel="AED"
                    register={register6("medicalCharges", { required: 'please enter medical charges' })}
                    disabled={user?.user_type == 'C' ? true : false}
                    postfix={user?.user_type == 'C' ? false : true}

                  />
                </Box>
              </Grid>
            <Grid item xs={12} mb={2} mt={2} sx={{display:'flex',gap:2,alignItems:'center'}}>
              { <Checkbox checked={monthlyChargesCheck} disabled={user?.user_type == 'O' ? false : true}   onChange={() => {setMonthlyChargesCheck(!monthlyChargesCheck);setValue6('monthlyVisaServiceCharges','');setValue6('monthlyVisaServiceChargesabove','')}} /> }
                   

            <Typography sx={{ fontSize: "18px", fontWeight: 'bold' }} >Monthly Visa Service Charges </Typography>
            </Grid>
              <Grid item xs={5}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>

                  <LabelCustomInput
                    label=" Below 2000 * : "
                    StartLabel="AED"
                    max={2000}
                    register={register6("monthlyVisaServiceCharges", { required:monthlyChargesCheck ?  'required' : false })}
                    disabled={user?.user_type == 'C' || !monthlyChargesCheck ? true : false}
                    postfix={user?.user_type == 'C' ? false : true}

                  />
                </Box>
              </Grid>
              <Grid item xs={5}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>

                  <LabelCustomInput
                    label=" Above 2000 * : "
                    StartLabel="AED"
                    register={register6("monthlyVisaServiceChargesabove", { required:monthlyChargesCheck ?  'required' : false })}
                    disabled={user?.user_type == 'C' || !monthlyChargesCheck ? true : false}
                    postfix={user?.user_type == 'C' ? false : true}

                  />
                </Box>
              </Grid>
              
            </Grid>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: Colors.textColorDarkBlue, mb: 2, mt: 3 }}>Extra Costing : </Typography>

            <Grid container sx={{ gap: '20px 25px' }}>

              <Grid item xs={5} >
                <LabelCustomInput label={'Cancellation Rates : '} StartLabel={'AED'} register={register6("cancellationRates", { required: "Enter cancellation rate" })}    disabled={user?.user_type == 'C' ? true : false} postfix={user?.user_type == 'C' ? false : true} />
              </Grid>
              <Grid item xs={5} >
                <LabelCustomInput label={'Overstay Cost : '} StartLabel={'AED'} register={register6("overstayCost", { required: "Enter year overstay rate" })}    disabled={user?.user_type == 'C' ? true : false} postfix={user?.user_type == 'C' ? false : true} />
              </Grid>
              <Grid item xs={5} >
                <LabelCustomInput label={'Rejection Cost : '} StartLabel={'AED'} register={register6("rejectionCost", { required: "Enter rejection cost" })}    disabled={user?.user_type == 'C' ? true : false} postfix={user?.user_type == 'C' ? false : true} />
              </Grid>
              <Grid item xs={5} >
                <LabelCustomInput label={'Absconder Fee : '} StartLabel={'AED'} register={register6("absconderFee", { required: "Enter absconder fee" })}    disabled={user?.user_type == 'C' ? true : false}  postfix={user?.user_type == 'C' ? false : true} />
              </Grid>


            </Grid>
          </Box>
        </Box>}
      </Box>
    </>
  );
}

export default UpdateCustomer;